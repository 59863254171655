import {useCli} from '../../hooks/useCli';

export function Loading(){

  const {isLoading} = useCli()

  if(isLoading){
    return(
      <div className="flex flex-row mx-2 my-3">
        <span className="animate-ping h-2 w-2 rounded-full bg-primary opacity-75"></span>
      </div>
    )
  }else{
    return <></>
  }

}
