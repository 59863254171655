import {Transition} from '@headlessui/react'
import {CheckCircleIcon} from '@heroicons/react/24/outline'
import {XMarkIcon} from '@heroicons/react/20/solid'
import React from 'react'

type ToastContextProps = {
  title?: string,
  description?: string,
  setShow: (show: boolean) => void,
  show: boolean,
  setMessage: (title: string, message: string, timeout: number) => void
}

export const useToast = () => {
  const context = React.useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context

}

const ToastContext = React.createContext<ToastContextProps>({
  setMessage(title: string, message: string, timeout: number): void {
  }, setShow(show: boolean): void {
  }, show: false,
});
export const ToastProvider = ({children}: { children: React.ReactNode }) => {

  const [show, setShow] = React.useState(false)
  const [title, setTitle] = React.useState<string>()
  const [description, setDescription] = React.useState<string>()

  const setMessage = (title: string, description: string, timeout: number = 3000) => {
    setTitle(title)
    setDescription(description)
    setShow(true)
    setTimeout(() => setShow(false), timeout)
  }

  return (
    <ToastContext.Provider value={{setMessage, title, description, show, setShow}}>
      <Toast/>
      {children}
    </ToastContext.Provider>
  )
};


export function Toast() {

  const {setShow, show, title, description} = useToast()

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-30 top-[55px] right-[33%]"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition show={show}>
            <div
              className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-glare text-primary"/>
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-glare font-vt !text-white text-sm">{title}</p>
                    <p className="mt-1 text-glare font-vt text-primary text-sm">{description}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false)
                      }}
                      className="inline-flex rounded-full bg-gray-800 text-primary hover:text-primary border-primary border"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-3 w-3"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
