import {useStore} from '../../hooks/useStore';
import {Button} from '../common/Button';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export function Store() {

  const {store} = useStore()

  return (
    <>
      <div
        className="isolate mx-auto grid grid-cols-1 gap-8 md:grid-cols-3 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
        {store.products.map((tier) => (
          <div
            key={tier.id}
            className="rounded p-8 bg-gray-800">
            <div className="mb-4 items-center gap-x-1 flex flex-row justify-center">
              <span
                className="text-4xl font-bold tracking-tight text-glare-secondary font-vt">{tier.prices[0].unit_amount / 100} €</span>
              <span className="text-4xl font-bold tracking-tight text-glare-secondary font-vt">
                <span className="material-symbols-outlined text-2xl mx-1">
                  compare_arrows
                </span>
              </span>
              <span className="text-4xl font-bold tracking-tight text-glare font-vt ">
                {tier.metadata.parts}
              </span>
              <img src="/images/parts4.jpg" alt="coin" className="w-10 h-10 rounded-full inline-block"/>
            </div>
            <h3
              id={tier.id}
              className={classNames(
                tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                'text-2xl font-bold leading-8 text-glare font-vt !text-white',
              )}
            >
              {tier.name}
            </h3>
            <div className="flex flex-col gap-4">
              <p className="mt-2 text-sm leading-6 text-primary text-glare font-vt">{tier.description}</p>
              <Button className="h-fit" href={tier.checkoutUrl}>
                Buy Now!
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
