import { useStore } from "../../hooks/useStore";
import {useAuth} from '../../hooks/useAuth';
import {useEffect, useState} from 'react';
import {useToast} from '../common/Toast';
import {useParams} from '../../hooks/useParams';

export function ShipPartsIcon(){
  const {setOpen} = useStore()
  const {auth} = useAuth()
  const [bounce, setBounce] = useState(false)

  const show = auth.user ? 'opacity-100' : 'opacity-0'

  const {setMessage} = useToast()
  const paymentStatus = useParams('payment-status')

  useEffect(() => {
    if(paymentStatus === 'succeeded'){
      setBounce(true)
      setTimeout(() => setBounce(false), 6000)
      setMessage('Ship Parts Acquired', 'Shiparts has been acquired. Please enjoy creating your new ship right now by using the command /create ship.', 6000)
    }
  }, [paymentStatus])

  return (
    <a onClick={() => setOpen(true)} className={`${show} transition-opacity duration-700 ease-in-out flex flex-row items-center gap-2 absolute right-0 h-[50px] mx-2 my-1 shadow hover:bg-gray-800 cursor-pointer px-2 rounded`}>
      <div className="flex flex-col">
        <div className="text-primary text-glare font-vt text-sm text-right">{auth.user?.parts}</div>
        <div className="text-primary text-glare-secondary font-vt text-xs text-right">Ship Parts</div>
      </div>
      <div className={`bg-no-repeat bg-center bg-cover w-8 h-8 rounded-full ${bounce?'animate-bounce':''}`} style={{backgroundImage: "url('/images/parts4.jpg')"}}></div>
    </a>
  )
}
