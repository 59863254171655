import {AppTooltip} from './Tooltip';
import {getPinataUrl} from '../../Utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShieldHalved, faShieldHeart, faGaugeHigh, faGun, faEye} from '@fortawesome/free-solid-svg-icons';
import {Ship as ShipDto} from '../../store/ships';
import {Button} from './Button';

const SHIP_URL = process.env.REACT_APP_SHIP_URL

const TRAIT_STYLES: any = {
  'S': {
    pill: ' text-yellow-500',
    bullet: '#eab308',
  },
  'A': {
    pill: ' text-pink-500',
    bullet: '#ec4899',
  },
  'B': {
    pill: ' text-purple-500',
    bullet: "#8b5cf6",
  },
  'C': {
    pill: ' text-blue-500',
    bullet: '#3b82f6',
  },
  'D': {
    pill: ' text-gray-500',
    bullet: '#6b7280',
  },
  'primary': {
    pill: 'border-primary text-primary text-glare',
    bullet: '#33ff33',
  },
  'secondary': {
    pill: 'border-secondary text-secondary text-glare-secondary',
    bullet: '#ff33aa',
  },
}

export type ShipProps = {
  ship: ShipDto
  stats?: {
    xp: number,
    level: number
    attack: number
    defense: number
    speed: number
    hp: number
  }
  active?: boolean
  onClick?: any
}

function ShipTrait({trait, preset = 'primary', id}: { trait: string, preset?: string, id?: string }) {
  return (
    <span
      id={id}
      className={`inline-flex items-center gap-x-1.5 rounded bg-gray-900 px-2 py-1 text-xs font-medium ${TRAIT_STYLES[preset]?.pill}`}>
        <svg viewBox="0 0 6 6" aria-hidden="true" className={`h-1.5 w-1.5 !${TRAIT_STYLES[preset]?.bullet}`}>
          <circle fill={TRAIT_STYLES[preset]?.bullet} r={3} cx={3} cy={3}/>
        </svg>
        <span>{trait}</span>
      </span>
  )
}

export function Ship({ship, stats, active = false, onClick}: ShipProps) {
  return (
    <div className="font-vt text-glare cursor-pointer p-2 gap-2" onClick={onClick}>
      <div
        className={`${active ? 'bg-gray-700' : 'bg-gray-800'} ${onClick ? 'hover:bg-gray-700' : ''} transition-all ease-in-out duration-100 relative max-w-2xl mx-auto text-primary rounded-md shadow-lg border-primary border-2 p-2 flex flex-col mt-2 gap-2`}>
        <div className="flex-1 pl-4">
          <AppTooltip text={
            <div className="max-w-xs z-50">
              <span className="text-glare-secondary">Spaceship Level:</span> As you play, your spaceship level
              increases, unlocking new features, enhancing your vessel's performance, and opening up more advanced and
              rewarding gameplay opportunities.
            </div>
          } id="level">
            <div
              id="level"
              className="absolute top-0 right-0 mr-2 mt-[-20px] bg-gray-900 glare-shadow rounded-full h-8 w-8 flex items-center justify-center border-2 border-primary">
              {(!stats || stats.level == ship.level) &&
                <span className="text-white text-md font-bold">{ship.level}</span>}
              {(stats && stats.level > ship.level) &&
                <span className="text-secondary text-md font-bold text-glare-secondary">{stats.level}</span>}
            </div>
          </AppTooltip>
        </div>
        <div className="flex flex-row gap-2">
          <img className="w-full max-w-[120px] h-[100%] rounded-lg"
               src={getPinataUrl(ship.image)} alt={ship.name}/>
          <div className="flex flex-col">
            <div className="text-xl font-bold mb-2 text-white">{ship.name}</div>
            <div className="text-sm max-h-[140px] overflow-hidden text-ellipsis w-full line-clamp-4"
                 title={ship.description}>{ship.description}</div>
          </div>
        </div>

        <div className="grid xs:grid-cols-1 md:grid-cols-4 gap-1">
          <div className="col-span-1 flex flex-row gap-1">
            {ship.race && <ShipTrait trait={ship.race} preset="primary"/>}
            <AppTooltip text={
              <div className="max-w-xs z-50">
                <span className="text-glare-secondary">Spaceship Tier:</span> Every spacecraft is assigned a specific
                tier
                that reflects its overall quality, capabilities, and rarity. This tiering system adds an element of
                excitement and variability to the ship creation process, as players may craft vessels of varying power
                and
                uniqueness
              </div>
            } id="tier">
              <ShipTrait id="tier" trait={ship.tier} preset={ship.tier}/>
            </AppTooltip>
          </div>
          <div className="col-span-3 flex xs:flex-row md:flex-row-reverse gap-1">
            {ship.traits.map((trait, index) => (
              trait && <ShipTrait key={index} trait={trait} preset="secondary"/>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 ">
          <div className="col-span-2">
            <div
              className="flex justify-center space-x-4 text-center bg-gray-900 rounded p-2 h-full items-center w-fit px-4">
              <div className="flex flex-col items-center gap-1">
                <FontAwesomeIcon icon={faGun} className="text-white text-lg"/>
                <p className="text-xs text-white">
                  <strong className="text-primary">Attack</strong> {ship.attack} {stats && stats.level > ship.level &&
                  <span className="text-glare-secondary">+{stats.attack - ship.attack}</span>} </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <FontAwesomeIcon icon={faShieldHalved} className="text-white text-lg"/>
                <p className="text-xs text-white">
                  <strong className="text-primary">Defense</strong> {ship.defense} {stats && stats.level > ship.level &&
                  <span className="text-glare-secondary">+{stats.defense - ship.defense}</span>}</p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <FontAwesomeIcon icon={faGaugeHigh} className="text-white text-lg"/>
                <p className="text-xs text-white">
                  <strong className="text-primary">Speed</strong> {ship.speed} {stats && stats.level > ship.level &&
                  <span className="text-glare-secondary">+{stats.speed - ship.speed}</span>}
                </p>
              </div>
              <div className="flex flex-col items-center gap-1">
                <FontAwesomeIcon icon={faShieldHeart} className="text-white text-lg fa-regular"/>
                <p className="text-xs text-white">
                  <strong className="text-primary ">Health</strong> {ship.hp} {stats && stats.level > ship.level &&
                  <span className="text-glare-secondary">+{stats.hp - ship.hp}</span>}
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-1 place-self-end h-full">
            <div className="w-full w-fit h-full">
              <Button href={`${SHIP_URL}/s/${ship.id}`}>
                <div className="flex flex-col gap-1 items-center mx-2">
                  <FontAwesomeIcon icon={faEye} className="text-white text-lg fa-regular"/>
                  <div>More</div>
                </div>
              </Button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
