import {DialogContainer} from '../common/DialogContainer';
import React, {useEffect, useState} from 'react';
import {GameFinish} from './GameFinish';
import {useGame} from '../../hooks/useGame';
import {useShips} from '../../hooks/useShips';
import {useSound} from '../common/Soundboard';

export function GameFinishDialog() {
  const [isOpen, setOpen] = useState(false)
  const {setShip, ships, load} = useShips()
  const {game} = useGame()
  const {play} = useSound()

  useEffect(() => {
    if(game.current?.finished) {
      setOpen(true)
      play("notification")
    }
  }, [game.current?.finished])

  function setEvolvedShip(){
    const stats = game.current?.stats

    const evolveShip = {...ships.current, ...stats}

    setShip(evolveShip, true)
    load()
    setOpen(false)
  }

  return(
    <DialogContainer isOpen={isOpen} setOpen={setEvolvedShip} size='max-w-lg'>
      <GameFinish setOpen={setEvolvedShip} />
    </DialogContainer>
  )
}
