import React, {useEffect, useRef} from 'react';
import TypingEffect from '../common/TypingEffect';
import {MessageTypes, useCli} from '../../hooks/useCli';
import {Loading} from '../common/Loading';
import {CliOptions} from './CliOptions';
import {setOptions} from '../../store/cli';
import {useAppDispatch} from '../../store/hooks';
import {useShips} from '../../hooks/useShips';
import {useAuth0} from '@auth0/auth0-react';
import {useGame} from '../../hooks/useGame';

const Cli = () => {
  const dispatch = useAppDispatch()
  const {isAuthenticated} = useAuth0();
  const {ships} = useShips()
  const {game} = useGame()
  const {processCommand, setInput, input, output, defaultOptions} = useCli()
  const [isAtBottom, setIsAtTheBotton] = React.useState(true)
  const [lastScroll, setLastScroll] = React.useState<number>(0);

  const outputEndRef = useRef<any>(null); // Ref for scrolling to the latest output

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setOptions(defaultOptions))
    } else {
      dispatch(setOptions([
        ...defaultOptions,
        {
          label: <><span className="text-glare-secondary">/login</span> - Login or register a new account</>,
          action: () => processCommand('/login'),
        },
      ]))
    }
  }, [useCli, ships.current])

  useEffect(() => {
    if(game.current?.finished){
      if (isAuthenticated) {
        dispatch(setOptions(defaultOptions))
      } else {
        dispatch(setOptions([
          ...defaultOptions,
          {
            label: <><span className="text-glare-secondary">/login</span> - Login or register a new account</>,
            action: () => processCommand('/login'),
          },
        ]))
      }
    }
  }, [game.current?.finished])

  useEffect(() => {
    window.addEventListener('scroll', function () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop < lastScroll) {
        setIsAtTheBotton(false)
      } else {
        setIsAtTheBotton(true)
      }
      setLastScroll(scrollTop <= 0 ? 0 : scrollTop);
    })
  }, [lastScroll]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAtBottom) {
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth', // for smooth scrolling
        })
      }
    }, 200)

    return () => {
      window.clearInterval(interval)
    }
  }, [isAtBottom])

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  const handleInputKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      processCommand(input);
      setInput('');
      setIsAtTheBotton(true)

    }
  };

  return (
    <div className="screen-distortion screen-blur bg-gray-800 p-3 lg:p-4">
      <div className="text-distortion flex flex-col gap-2">
        <div className="cli-output-container cli-output whitespace-pre-wrap overflow-hidden font-vt ">
          <div className="text-primary text-glare font-vt text-[11px] leading-none mt-4 text-center hidden lg:block">
            {
              `     
888       888          888                                                888                   888     888                                                       .d8888b.  8888888b. 88888888888 
888   o   888          888                                                888                   888     888                                                      d88P  Y88b 888   Y88b    888     
888  d8b  888          888                                                888                   888     888                                                      888    888 888    888    888     
888 d888b 888  .d88b.  888  .d8888b  .d88b.  88888b.d88b.   .d88b.        888888  .d88b.        Y88b   d88P  .d88b.  888  888  8888b.   .d88b.   .d88b.  888d888 888        888   d88P    888     
888d88888b888 d8P  Y8b 888 d88P"    d88""88b 888 "888 "88b d8P  Y8b       888    d88""88b        Y88b d88P  d88""88b 888  888     "88b d88P"88b d8P  Y8b 888P"   888  88888 8888888P"     888     
88888P Y88888 88888888 888 888      888  888 888  888  888 88888888       888    888  888         Y88o88P   888  888 888  888 .d888888 888  888 88888888 888     888    888 888           888     
8888P   Y8888 Y8b.     888 Y88b.    Y88..88P 888  888  888 Y8b.           Y88b.  Y88..88P          Y888P    Y88..88P Y88b 888 888  888 Y88b 888 Y8b.     888     Y88b  d88P 888           888     
888P     Y888  "Y8888  888  "Y8888P  "Y88P"  888  888  888  "Y8888         "Y888  "Y88P"            Y8P      "Y88P"   "Y88888 "Y888888  "Y88888  "Y8888  888      "Y8888P88 888           888     
                                                                                                                          888               888                                                   
                                                                                                                     Y8b d88P          Y8b d88P                                                   
                                                                                                                      "Y88P"            "Y88P"                                                    
              `
            }
          </div>
          <div className="text-primary text-glare font-vt text-[11px] leading-none mt-4 text-center lg:hidden">
            {
              `

#     #                                           #####  ######  ####### 
#     #  ####  #   #   ##    ####  ###### #####  #     # #     #    #    
#     # #    #  # #   #  #  #    # #      #    # #       #     #    #    
#     # #    #   #   #    # #      #####  #    # #  #### ######     #    
 #   #  #    #   #   ###### #  ### #      #####  #     # #          #    
  # #   #    #   #   #    # #    # #      #   #  #     # #          #    
   #     ####    #   #    #  ####  ###### #    #  #####  #          #    
                      
              `
            }
          </div>
          <div className="text-xl">
            <div className="text-glare leading-tight">
              <TypingEffect text={
                `VoyagerGPT is an immersive text-based space adventure game where players explore the universe, craft unique ships, and face exciting challenges. The game combines space exploration with blockchain technology, allowing players to mint their ships as digital assets.

Players can evolve their ships, share adventures with a community, and mint their creations on popular blockchains. This process turns ships into digital assets, introducing them to the world of NFTs.

The game features a competitive NFT marketplace where players can trade and showcase their ships. This not only encourages creativity but also taps into the digital economy, offering new opportunities for players in the expanding digital landscape.`
              } speed={2}/>
            </div>
            {output.map((line: any, index: any) => (
              <div className="text-glare overflow-hidden overscroll-y-none leading-tight" key={index}>
                {
                  line.type === MessageTypes.CLI ?
                    <div className=""><TypingEffect text={line.message} speed={5}/></div> :
                    <div className="glare-text-secondary px-2 py-4">{line.message}</div>
                }
              </div>
            ))}
          </div>
          <Loading/>
          <div ref={outputEndRef}/>
          {/* Invisible element to mark the end */}
        </div>
        <CliOptions/>
        <input
          type="text"
          className="w-full bg-gray-900 p-2 mt-2 input-glare border-none focus:outline-none"
          placeholder="Type a command..."
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
        />
      </div>
    </div>
  );
};

export default Cli;
