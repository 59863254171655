import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL

type AuthState = {
  user?: any
}

const initialState: AuthState = {
  user: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
});

export const me = createAsyncThunk(
  'auth/me',
  async (arg: any, {dispatch, rejectWithValue}) => {
    try {
      const result = await axios.get(`${url}/auth/me`, {
        headers: {
          Authorization: `Bearer ${arg}`,
        },
      })

      const user = result.data

      dispatch(setUser(user))

    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
)

export const { setUser } = authSlice.actions

export default authSlice.reducer
