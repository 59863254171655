import React, {useContext} from "react";

export type Sound = {
  id: string;
  name: string;
  url: string;
  playing?: boolean;
  audio?: HTMLAudioElement;
  volume?: number;
}

type SoundContext = {
  sounds: Record<string, Sound>;
  muted?: boolean
  setMuted?: (muted: boolean) => void;
}

const SoundContext = React.createContext<SoundContext>({sounds: {}, muted: true});

export const useSound = () => {
  const {sounds, muted} = useContext(SoundContext);

  const play = (id: string) => {
    const sound = sounds[id];
    if(sound.audio && !muted) {
      if(sound.volume) {
        sound.audio.volume = sound.volume;
      }
      return sound.audio.play()
    }
  }

  return {play, sounds};
}

type SoundProviderProps = {
  sounds: Sound[];
  children: React.ReactNode;
}

export function SoundBoard({children, sounds}: SoundProviderProps) {
  const soundsRecord = sounds.reduce((acc, sound) => ({...acc, [sound.id]: {...sound, audio: new Audio(sound.url)}}), {});

  const [muted, setMuted] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setMuted(false), 4000)
  }, [])

  return <SoundContext.Provider value={{sounds: soundsRecord, muted, setMuted}}>
    {children}
  </SoundContext.Provider>;
}
