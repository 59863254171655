import {useAuth0} from '@auth0/auth0-react';
import {useEffect} from 'react';

export function UserProfileIcon() {
  const {user, getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((token) => console.log(token));
  }, [getAccessTokenSilently])

  if (user?.picture) {

    return (
      <div className="flex flex-col items-center cursor-pointer rounded hover:bg-gray-800 p-2">
        <div className='p-1'>
          <img
            alt=""
            src={user?.picture}
            className="h-auto w-full rounded"
          />
        </div>
        <div className="font-vt text-xs text-center break-words line-clamp-2 w-full text-glare">{user.name}</div>
      </div>
    )
  } else {
    const initials = user?.name?.split(' ').map((n: string) => n[0]).join('');

    return (
      <div className="flex flex-row justify-center w-full">
        <span className="inline-flex h-11 w-11 items-center justify-center rounded bg-gray-800">
          <span className="text-xs font-medium leading-none text-glare text-white">{initials}</span>
        </span>
      </div>
    )
  }

}
