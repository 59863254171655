
export function useParams(name: string){
  // Get the query string from the current URL
  const queryString = window.location.search;

  // Parse the query string using URLSearchParams
  const urlParams = new URLSearchParams(queryString);

  // Get the value of a specific parameter (e.g., 'paramName')
  return  urlParams.get(name);
}
