import {AppTooltip} from './Tooltip'
import {useEffect, useRef, useState} from 'react';

type ButtonProps = {
  style?: 'primary' | 'secondary'
  id?: string
  children: React.ReactNode
  action?: () => void
  href?: string
  className?: string
  disabled?: boolean
}


const styleClass = {
  primary: 'bg-gray-900 hover:bg-gray-800 text-primary hover:border-primary border border-gray-900 transition-all ease-in-out duration-100',
  secondary: 'bg-gray-800 hover:bg-gray-700 text-primary hover:border-primary border border-gray-900  transition-all ease-in-out duration-100'
}

export function Button({action, href, children, className, id="bt", style="primary",disabled=false}: ButtonProps) {

  const css = styleClass[style]

  return (
    <a
      onClick={() => {
        if(!disabled){
          action?.()
        }
      }}
      href={href}
      className={`${css} cursor-pointer text-center font-vt ring-primary p-2 rounded trasition-all ease-in-out duration-100 h-full flex justify-center items-center ${className}`}>
        {children}
    </a>
  )
}
