import {Button} from '../common/Button';
import {useCli} from '../../hooks/useCli';


export function CliOptions() {

  const {options} = useCli()

  if (options && options.length > 0) {
    return (
      <div className="flex flex-col lg:flex lg:flex-row gap-2 mt-2">
        {options.map((option, index) =>
          <Button id={option.id} action={option.action}>{option.label}</Button>,
        )}
      </div>
    )
  } else {
    return <></>
  }
}
