import React, {useState} from 'react';
import {prompt, clear} from '../store/cli';
import {RootState} from '../store';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {useShips} from './useShips';
import {useGame} from './useGame'
import {useSound} from '../components/common/Soundboard';
import {useAuth0} from '@auth0/auth0-react';
import {useStore} from './useStore';

const url = process.env.REACT_APP_API_URL

export enum MessageTypes {
  CLI = 'cli',
  ASSISTANT = 'assistant',
  PROMPT = 'prompt'

}

export type Message = {
  type: MessageTypes;
  message: string
}

export function useCli() {

  const defaultOptions = [
    {
      id: 'help',
      label: <><span className="text-glare-secondary">/help</span> - List commands</>,
      action: () => processCommand('/help'),
    },
    {
      id: 'createship',
      label: <><span className="text-glare-secondary">/ship create</span> - Generate a new ship</>,
      action: () => processCommand('/ship create'),
    },
    {
      id: 'gamestart',
      label: <><span className="text-glare-secondary">/game start</span> - Stat a new adventure</>,
      action: () => processCommand('/game start'),
    },
  ]

  const [input, setInput] = useState<any>('');
  const {create: createShip, ships} = useShips();
  const {play} = useSound();
  const {setOpen: setOpenStore} = useStore();
  const {isAuthenticated, loginWithRedirect} = useAuth0();
  const {createGame, stepGame} = useGame();

  const dispatch = useAppDispatch();
  const {output, isLoading, options} = useAppSelector((state: RootState) => state.cli)

  const {thread} = useAppSelector((state: RootState) => state.game)

  function processCommand(command: string) {

    dispatch(prompt(
      {
        type: MessageTypes.PROMPT,
        message: `$ ${command} \n`,
      },
    ))
    play("command")


    switch (command.toLowerCase()) {
      case '/store open':
        setOpenStore(true)
        break
      case '/login':
        loginWithRedirect()
        break
      case '/help':
        dispatch(prompt(
          {
            type: MessageTypes.CLI,
            message: 'Available commands: \n /help - View all commands \n /ship create - Generate a new ship \n /game start - Start a new adventure \n /clear - Clear the screen \n /login - Login or register a new account',
          },
        ))
        break;
      case '/clear':
        clear();
        return;
      default:
        if (command.toLowerCase().startsWith('/game start')) {
          if (!ships.current) {
            dispatch(prompt(
              {
                type: MessageTypes.CLI,
                message: 'You need to create a ship first. Please run the command /ship create',
              },
            ))
          } else {
            createGame()
          }
        } else if (command.toLowerCase().startsWith('/ship create')) {
          if(isAuthenticated){
            createShip()
          }else{
            dispatch(prompt({
              type: MessageTypes.CLI,
              message: 'You need to be logged in to create a ship. Just type /login to register or login into your account.'
            }))
          }
        } else {
          if (thread) {
            stepGame(command)
          } else {
            dispatch(prompt(
              {
                type: MessageTypes.CLI,
                message: `Command not found: ${command}`,
              },
            ))
          }
        }
    }
  }

  return {input, setInput, output, processCommand, isLoading, options, defaultOptions}
}
