import {ReactElement} from 'react';

type MenuButtonProps = {
  icon: ReactElement;
  label?: string
  onClick?: () => void
  href?: string
  target?: string
  active?: boolean
}

export function MenuButton({icon, label, onClick, href, target, active=false}: MenuButtonProps) {
  return (
    <a onClick={onClick}
       target={target}
       href={href}
       className={`${active?'bg-gray-800':'bg-gray-900'} flex flex-col items-center justify-center cursor-pointer hover:bg-gray-800 rounded p-1 w-full h-full`} >
      <div className="text-primary">
        {icon}
      </div>
      {label && <div className="text-glare text-xs font-vt w-full text-center">{label}</div>}
    </a>
  );
}
