import {DialogContainer} from '../common/DialogContainer';
import {Store} from '../core/Store';
import React, {useState} from 'react';
import {useStore} from '../../hooks/useStore';

type StoreContextProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
}

export const StoreContext = React.createContext<StoreContextProps>({
  isOpen: false,
  setOpen: () => {}
});

export function StoreProvider({children}: {children: React.ReactNode}) {
  const [isOpen, setOpen] = useState(false);
  return (
    <StoreContext.Provider value={{isOpen, setOpen}}>
      {children}
    </StoreContext.Provider>
  )
}

export function StoreDialog() {
  const {isOpen, setOpen} = useStore()

  return(
    <DialogContainer isOpen={isOpen} setOpen={setOpen}>
      <Store />
    </DialogContainer>
  )
}
