import {Ship} from '../common/ShipV2';
import {useShips} from '../../hooks/useShips';
import {useState, useEffect} from 'react';
import {Counter} from '../common/Counter';
import {Confetti} from '../common/Confetti';
import {Button} from '../common/Button';
import {useGame} from '../../hooks/useGame';

type ProgressBarProps = {
  current: number;
  earned: number;
  level: number;
};

const baseXP = 100; // XP required for level 1
const exponent = 1.5;

function getExponentialXP(level:number) {
  return Math.floor(
    baseXP * Math.pow(level, exponent),
  );
}

function getXpFromLevel(level:number) {
  let baseXp = 0
  for(let i = 1; i < level; i++){
    baseXp += getExponentialXP(i)
  }
  return baseXp
}

function getLevelFromXP(totalXP:number) {
  let level = 1;
  let xpForNextLevel = getExponentialXP(level);

  while (totalXP >= xpForNextLevel) {
    totalXP -= xpForNextLevel;
    level++;
    xpForNextLevel = getExponentialXP(level);
  }

  return level;
}

function calculateProgress(xpStart:number, xpEnd:number, currentXP:number) {
  if (currentXP <= xpStart) {
    return 0; // No progress if current XP is less than or equal to start XP
  }
  if (currentXP >= xpEnd) {
    return 100; // Full progress if current XP is greater than or equal to end XP
  }

  return ((currentXP - xpStart) / (xpEnd - xpStart)) * 100;
}

export function ProgressBar({ current, earned }: ProgressBarProps) {

  const [isAnimating, setIsAnimating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [levelStart, setLevelStart] = useState(getLevelFromXP(current));
  const [levelEnd, setLevelEnd] = useState(getLevelFromXP(current)+1);

  useEffect(() => {
    setIsAnimating(false)
    const xpLevelEnd = getXpFromLevel(levelEnd);
    const xpLevelStart = getXpFromLevel(levelStart)
    setProgress(calculateProgress(xpLevelStart, xpLevelEnd, current));
    setTimeout(() => {
      setIsAnimating(true)
      const xpLevelEnd = getXpFromLevel(levelEnd);
      const xpLevelStart = getXpFromLevel(levelStart)
      setProgress(calculateProgress(xpLevelStart, xpLevelEnd, earned));
    }, 1000)
  },[])

  useEffect(() => {
    setIsAnimating(false)
    const xpLevelEnd = getXpFromLevel(levelEnd);
    const xpLevelStart = getXpFromLevel(levelStart)
    setProgress(calculateProgress(xpLevelStart, xpLevelEnd, current));
    setTimeout(() => {
      setIsAnimating(true)
      const xpLevelEnd = getXpFromLevel(levelEnd);
      const xpLevelStart = getXpFromLevel(levelStart)
      setProgress(calculateProgress(xpLevelStart, xpLevelEnd, earned));
    }, 1000)
  },[earned])

  return (
    <>
      <div className="w-full bg-gray-200 rounded dark:bg-gray-700 p-2">
        <div
          className={`bg-primary text-md font-medium text-secondary font-vt text-glare-secondary text-center p-0.5 leading-none rounded transition-all ${
            isAnimating ? 'duration-1000' : ''
          } ease-in-out`}
          style={{ width: `${progress > 100 ? 100 : progress}%` }}
        >
          <span className="line-clamp-1 break-words">
            +<Counter total={earned - current} delay={10} />
          </span>
        </div>
      </div>
      <div className="grid grid-cols-2 font-vt text-glare text-xs">
        <div className="col-span-1 text-left">Level {levelStart}</div>
        <div className={`col-span-1 text-right ${progress >= 100 ? 'animate-pulse' : ''}`}>
          Level {levelEnd}
        </div>
      </div>
    </>
  );
}

export function GameFinish({setOpen}: any){
  const {game} = useGame();
  const {ships} = useShips();

  if(game.current && game.current.finished && game.current.stats){

    return (
      <div className="flex flex-col gap-2">
        <Confetti/>
        <h1 className="text-white font-vt text-glare text-4xl text-center animate-pulse mb-2">Mission Sucessfull</h1>
        {ships.current && <Ship ship={ships.current} stats={game.current.stats}/>}
        {ships.current && <ProgressBar earned={game.current.stats.xp} current={ships.current.xp} level={game.current.stats.level}/>}
        <Button id="continue" style="secondary" action={() => setOpen(false)}>
          Continue Playing ...
        </Button>
      </div>
    )
  }else{
    return <></>
  }

}
