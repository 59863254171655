import {useAuth0} from '@auth0/auth0-react';
import {UserProfileIcon} from './UserProfileIcon';
import {ShipList} from './ShipList';
import { useState} from 'react';
import {AppTooltip} from '../common/Tooltip';
import packageInfo from "../../../package.json";
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faRightToBracket, faRightFromBracket, faRocket } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MenuButton} from '../common/MenuButton';


const DISCORD_LINK = "https://discord.gg/xd6ZxFXSvT"

const enum MenuState {
  Ships
}

type NavigationProps = {
  menu: MenuState;
  setMenu: (menu: MenuState) => void;
}

function Navigation({menu, setMenu}: NavigationProps) {
  const {loginWithRedirect, logout, isAuthenticated} = useAuth0();

  return (
    <div className="flex flex-col h-full">
      <div className="p-1 mb-2">
        <AppTooltip id="logo" text={`VoyagerGPT - v${packageInfo.version}`}>
          <img id="logo" src="/logo2.jpg" alt="logo" className="w-full h-fit rounded"/>
        </AppTooltip>
      </div>
      <nav>
        <ul className="flex flex-col gap-1">
          {/*<li className="pb-2">*/}
          {/*  <MenuButton icon="overview" label="Overview"/>*/}
          {/*</li>*/}
          {/*<li className="pb-2">*/}
          {/*  <MenuButton icon="emoji_events" label="Achivements"/>*/}
          {/*</li>*/}
          <li className="pb-2 h-auto">
            <MenuButton active={menu===MenuState.Ships} icon={<FontAwesomeIcon icon={faRocket} />} label="My Ships"/>
          </li>
        </ul>
      </nav>
      <nav className='h-full'>
        <ul className="h-full flex flex-col-reverse gap-1 ">
          <li>
            {isAuthenticated && <MenuButton icon={<FontAwesomeIcon icon={faRightFromBracket} />} label="Logout" onClick={() => logout()}/>}
            {!isAuthenticated && <MenuButton icon={<FontAwesomeIcon icon={faRightToBracket} />} label="Login" onClick={() => loginWithRedirect()}/>}
          </li>
          {isAuthenticated &&
            <li className="h-auto">
              <UserProfileIcon/>
            </li>
          }
          <li>
            <MenuButton icon={<FontAwesomeIcon className="fa-bounce" icon={faDiscord} />} label="Discord" target="_blank" href={DISCORD_LINK}/>
          </li>
        </ul>
      </nav>
    </div>
  )
}



export function Menu() {

  const [menu, setMenu] = useState<MenuState>(MenuState.Ships);

  return (
    <div className="grid grid-cols-6 2xl:grid-cols-8 gap-1 h-screen">
      <div className="col-span-5 2xl:col-span-7 ">
        <div className="bg-gray-900 h-screen overflow-y-scroll text-primary">
          {menu === MenuState.Ships && <ShipList />}
        </div>
      </div>
      <div className="col-span-1 bg-gray-900 p-2 h-full text-primary">
        <Navigation menu={menu} setMenu={setMenu}/>
      </div>
    </div>
  );
}
