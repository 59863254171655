import 'react-tooltip/dist/react-tooltip.css'


import React, {useEffect} from 'react';
import Cli from './components/core/Cli';
import './index.css';
import {Menu} from './components/navigation/Menu';
import {useShips} from './hooks/useShips';
import {useAuth0} from '@auth0/auth0-react';
import {Sound, SoundBoard} from './components/common/Soundboard';
import {TopNav} from './components/navigation/TopNav';
import {StoreDialog, StoreProvider} from './components/core/StoreDialog';
import {useStore} from './hooks/useStore';
import {useAppDispatch} from './store/hooks';
import {me} from './store/auth';
import {ToastProvider} from './components/common/Toast';
import {setOptions} from './store/cli';
import {useCli} from './hooks/useCli';
import {GameFinishDialog} from './components/core/GameFinishDialog';
import {ShipCreatorDisplay} from './components/core/ShipCreatorDisplay';


const sounds: Array<Sound> = [
  {
    id: 'keyboard',
    name: 'Keyboard',
    url: 'sounds/click_002.ogg',
    volume: 0.4,
  },
  {
    id: 'command',
    name: 'Command',
    url: 'sounds/bong_001.ogg',
  },
  {
    id: 'notification',
    name: 'Notification',
    url: 'sounds/question_003.ogg',
  },
];



function App() {

  const {user, getAccessTokenSilently, isLoading} = useAuth0();
  const {load} = useShips();
  const {doFetchProduct} = useStore();
  const dispatch = useAppDispatch()

  useEffect(() => {
    load().then(() => console.log('Ships loaded ...'));
    getAccessTokenSilently().then((token) => dispatch(me(token)));
  }, [user, getAccessTokenSilently, isLoading])

  useEffect(() => {
    doFetchProduct()
  }, [user])

  return (
    <SoundBoard sounds={sounds}>
      <ToastProvider>
        <StoreProvider>
          <div className="App">
            <main>
              <StoreDialog/>
              <div className="grid xs:grid-cols-1 lg:grid-cols-3 gap-x-1 ">
                <div className="xs:col-span-3 lg:col-span-2">
                  <TopNav/>
                  <Cli/>
                  <GameFinishDialog />
                  <ShipCreatorDisplay />
                </div>
                <div className="col-span-1">
                  <div className="hidden lg:block">
                    <div className="fixed">
                    <Menu/>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </StoreProvider>
      </ToastProvider>
    </SoundBoard>
  );
}

export default App;
