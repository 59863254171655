import {useAuth0} from '@auth0/auth0-react';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {createShip, getShips, setCurrentShip, setShips} from '../store/ships';
import {MessageTypes, prompt} from '../store/cli';
import {useStore} from './useStore';
import {useAuth} from './useAuth';

export const useShips = () => {
  const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
  const dispatch = useAppDispatch();
  const ships = useAppSelector((state) => state.ships);
  const {auth} = useAuth()

  const load = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      dispatch(getShips({token}))
    } else {
      if(isLoading) return
      const defaultShip = {
        "name": "Junkyard Cruiser",
        "hp": 100,
        "attack": 1,
        "defense": 1,
        "speed": 1,
        "image": "QmT9SHNVFXKiQTbqxrCqWsx3ekoUKcXtm9q6aWDR7Kxk7A",
        "description": "This spaceship is a haphazardly assembled contraption, cobbled together from a chaotic mix of rusty metal sheets, broken pipes, and mismatched panels. The structure is visibly unstable, with parts held together by duct tape and loose wires, giving the impression that it could fall apart at any moment. Strange items, like a broken TV antenna and old furniture, are inexplicably attached to the hull, adding to the absurdity of its design. Floating aimlessly in space, this ship looks more like a collection of junkyard scraps than a functional spacecraft, making it a perilous and comically impractical vessel.",
        "tier": "Z",
        "level": 1,
        'xp': 0,
        'race': 'unknown',
        'traits': ['junk','scrap','rusty','unstable','chaotic','impractical'],
      }

      dispatch(setShips([defaultShip]))
      dispatch(setCurrentShip(defaultShip))
    }
  }

  const create = async () => {

    debugger
      if(auth.user?.parts && auth.user?.parts >= 130){
        if(isAuthenticated) {
          const token = await getAccessTokenSilently();
          dispatch(createShip({token}))
        }
      }else{
        dispatch(prompt({
          type: MessageTypes.CLI,
          message: `You need at least 130 ship parts to craft a new ship. You currently have ${auth.user?.parts} ship parts. For more ship parts type /store open.`,
        }))
      }
  }

  const setShip = (ship: any, silent=false) => {
    dispatch(setCurrentShip(ship))
    if(!silent) {
      dispatch(prompt({
        type: MessageTypes.CLI,
        message: `Welcome aboard the "${ship.name}" captain!`,
      }))
    }
  }

  return {ships, create, load, setShip}
}
