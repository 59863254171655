import { Tooltip } from 'react-tooltip';

type TooltipProps = {
  children: React.ReactNode;
  text: any;
  id: string;
  delayShow?:number
  show?: boolean;
}

export function AppTooltip({ children, text, id, delayShow, show=true }: TooltipProps) {
  return (
    <>
      {show && <Tooltip classNameArrow='!bg-gray-800 border-b border-r border-primary !opacity-100 z-50' className="!bg-gray-800 p-4 border-primary border !text-primary font-vt !opacity-100 z-50" delayShow={delayShow} anchorSelect={`#${id}`} content={text} />}
      {children}
    </>
  );
}
