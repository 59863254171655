import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {append, MessageTypes, prompt, setIsLoading, setIsLoading as setCliLoading, setOptions, setThread} from '../cli';
import {getShips} from '../ships';

const url = process.env.REACT_APP_API_URL

type Mission = {
  name?: string,
  description?: string,
  finished?: boolean
  levelUp?: boolean
  stats?: {
    xp: number
    level: number
    attack: number
    defense: number
    speed: number
    hp: number
  }
}

type GameState = {
  missions?: Array<Mission>
  current?: Mission
  thread?: string,
}

const initialState: GameState = {
  missions: undefined,
  current: undefined,
  thread: undefined,
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setMissions: (state, action) => {
      state.missions = action.payload
    },
    setCurrentMission: (state, action) => {
      const {current, thread} = action.payload
      state.current = current
      state.thread = thread
    },
    setFinished: (state, action) => {
      const {levelUp, stats} = action.payload
      state.current = {...state.current, finished: true, levelUp, stats}
    }
  },
})


function createEvent(dispatch: any, thread:string, quest:boolean) {
  const eventSource = new EventSource(`${url}/game/${thread}/events?quest=${quest}`);

  eventSource.onmessage = function (event) {
    dispatch(setIsLoading(false))
    let {message} = JSON.parse(event.data)

    if(message && message.includes("GG")){
      message = message.replace("GG!", "")
      message = message.replace("GG.", "")
      message = message.replace("GG", "")
      setTimeout(() => {
        dispatch(completeGame({thread}))
      }, 1000)

    }
    dispatch(append({
      type: MessageTypes.CLI,
      message: message,
    }))
  };

  eventSource.onerror = function (err) {
    console.log(err)
    dispatch(setIsLoading(false))
    dispatch(setThread())
    eventSource.close();
  };

  return eventSource
}

export const completeGame = createAsyncThunk(
  'game/complete',
  async (arg:any, {dispatch, rejectWithValue}) => {
    dispatch(setCliLoading(true))


    const result = await axios.post(`${url}/game/${arg.thread}/complete`,  );

    const {levelUp, stats} = result.data


    dispatch(setFinished({levelUp, stats}))
    dispatch(setCliLoading(false))
  })

export const stepGame = createAsyncThunk(
  'game/step',
  async (arg:any, {dispatch, rejectWithValue}) => {
    dispatch(setCliLoading(true))
    dispatch(prompt({
      type: MessageTypes.CLI,
      message: '',
    }))

    let headers
    let quest = false
    if(arg.token){
      headers = {
        'Authorization': `Bearer ${arg.token}`
      }
    }

    const result = await axios.post(`${url}/game/${arg.thread}/step`, {message:arg.message}, {headers});
    dispatch(setCliLoading(false))

    createEvent(dispatch, arg.thread, quest)
  })

export const createGame = createAsyncThunk(
  'game/create',
  async (arg: any, {dispatch, rejectWithValue}) => {
    try {
      dispatch(setCliLoading(true))

      let headers
      let quest = false
      if(arg.token){
        headers = {
          'Authorization': `Bearer ${arg.token}`
        }
        quest = true
      }

      let body
      if(arg.ship){
        body = {ship: arg.ship}
      }

      const {data} = await axios.post(`${url}/game/create`, body, {headers});
      dispatch(setCliLoading(false))
      dispatch(prompt({
        type: MessageTypes.CLI,
        message: `Starting mission: ${data.name} ...\n\n`
      }))
      dispatch(setOptions([]))
      dispatch(setCurrentMission({
        current: {
          name: data.name,
          description: data.description
        },
        thread: data.thread
      }))
      createEvent(dispatch, data.thread, quest)
    } catch (error: any) {
      return rejectWithValue(error);
    }
  })

export const { setMissions, setCurrentMission, setFinished} = gameSlice.actions
export default gameSlice.reducer
