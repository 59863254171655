import {useEffect, useState} from 'react';

export type CounterProps = {
  total: number,
  delay: number
}

export function Counter({total, delay}: CounterProps) {

  const [count, setCount] = useState(0)

  useEffect(() => {
    const i = setInterval(() => {
      if(count < total){
        setCount(count + 1)
      }else{
        clearInterval(i)
      }
    }, delay)
    return () => clearInterval(i)
  }, [count, total, delay])

  return (
    <>{count}</>
  )

}
