import {useShips} from '../../hooks/useShips';
import {Ship as ShipDto} from '../../store/ships';
import TypingEffect from '../common/TypingEffect';
import {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRocket} from '@fortawesome/free-solid-svg-icons';
import {useAppDispatch} from '../../store/hooks';
import {MessageTypes, prompt} from '../../store/cli';
import {Ship} from '../common/ShipV2';

export type ShipProps = {
  ship: ShipDto
}

const LOADING_MESSAGES = [
  "Cleaning the angar ...",
  "Polishing the ships ...",
  "Checking the engines ...",
  "Preparing the ships ...",
  "Checking the fuel ...",
  "Checking the shields ...",
  "Checking the weapons ...",
  "Checking the navigation systems ...",
  "Checking the life support systems ...",
  "Checking the sensors ...",
  "Checking the communication systems ...",
  "Purgin the ship logs ...",
  "Preparing the ships for launch ...",
  "Cleaning the windows ...",
  "Checking the landing gear ...",
  "Checking the thrusters ...",
  "Checking the power systems ...",
]

function getMessage() {
  return LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]
}

function EmptyState() {

  const dispatch = useAppDispatch()
  const {create} = useShips()

  const doCreate = () => {
    dispatch(prompt({
      type: MessageTypes.CLI,
      message: '/ship create',
    }))
    create()
  }

  return (
    <div onClick={doCreate}
         className="text-center w-auto p-6 m-2 cursor-pointer hover:bg-gray-700 bg-gray-800 flex flex-col rounded-md ">
      <div className="text-center text-glare gap-2 mt-4 !text-white text-lg font-vt">
        <FontAwesomeIcon icon={faRocket} size="4x" className="fa-beat-fade text-primary mb-2"/>
        <div>
          <TypingEffect text="No ships available" resetable={true} speed={10}/>
        </div>
        <div className="text-primary">
          <TypingEffect text="Click here to generate a new ship just for you ..." resetable={true} speed={10}/>
        </div>
      </div>
    </div>
  )

}

function LoadingShips() {


  const [message, setMessage] = useState<string>("LoadingShips ships ...")

  useEffect(() => {
    setInterval(() => {
      setMessage(getMessage())
    }, 2000)
  }, [])

  return (
    <div className="text-center w-auto px-8 py-12 mx-2 bg-gray-800 mb-1 font-vt">
      <div role="status">
        <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
             viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"/>
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"/>
        </svg>
      </div>
      <div className="text-center text-glare gap-2 mt-4 !text-white text-lg ">
        <TypingEffect text={message} speed={10} resetable={true}/>
      </div>
    </div>
  )
}

export function ShipList() {

  const {ships, setShip} = useShips()
  const show = ships.list && ships.list.length > 0 ? 'opacity-100' : 'opacity-0'

  return (
    <>
      {ships.list && ships.list.length === 0 && !ships.isLoading && <EmptyState/>}
      <div className={`flex flex-col gap-2 transition-opacity duration-700 ease-in-out ${show}`}>
        <div className="bg-gray-800 p-4 mx-2 rounded">
          <h3 className="text-glare font-vt text-2xl font-bold mb-2 !text-white">My Ships</h3>
          <p className="text-glare text-sm font-vt py-1">
            Please choose a ship from the list below to start your adventure. Each ship comes with its own unique
            features and capabilities, allowing you to explore new horizons. If at any point you feel, you have the
            option to craft a new ship at any time by typing the command <span className="text-glare-secondary">/ship create</span>.
          </p>
        </div>
        {(ships.isLoading || !ships.list) && <LoadingShips/>}
        {ships.list && ships.list.map((ship) => <Ship key={ship.id} ship={ship} onClick={() => setShip(ship)} active={ships.current === ship}/>)}
      </div>
    </>
  );
}
