import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios';
import React from 'react';

export enum MessageTypes {
  CLI = 'cli',
  PROMPT = 'prompt',
  ASSISTANT = 'assistant'
}

export type Message = {
  type : MessageTypes;
  message?: string
}

export type Option = {
  id?:string,
  action: () => void,
  label: string | React.ReactElement
}

export type Ship = {
  name: string,
  hp: number,
  attack: number,
  defense: number,
  speed: number,
  url: string
  description:string,
  tier: string
}

export type CliState = {
  output: Array<Message>,
  thread?: string
  ship?: Ship,
  event: number
  isLoading: boolean,
  options: Array<Option>
}

const initialState: CliState = {
  output: new Array<Message>(),
  thread: undefined,
  ship: undefined,
  event: 0,
  isLoading: false,
  options: []
}

export const cliSlice = createSlice({
  name: 'cli',
  initialState,
  reducers: {
    prompt(state : any, action : PayloadAction<Message>){
      const output = action.payload
      //if(output.message){
      state.output.push(output)
      //}
    },
    append(state: any, action: PayloadAction<Message>){
      state.output[state.output.length - 1].message += action.payload.message
    },
    clear(state : any){
      state.output = new Array<Message>()
    },
    setThread(state : any, action : PayloadAction<String | undefined>) {
      state.thread = action.payload
    },
    setShip(state : any, action : PayloadAction<Ship>) {
      state.ship = action.payload
    },
    incrementEvent(state : any) {
      state.event = state.event + 1
    },
    setIsLoading(state : any, action : PayloadAction<boolean>){
      state.isLoading = action.payload
    },
    setOptions(state : any, action : PayloadAction<Array<Option>>){
      state.options = action.payload
    }
  },
})

type StartGameArgs = {
  ship: Ship;
}

export const startGamme = createAsyncThunk(
  'cli/startGame',
  async (arg: StartGameArgs, { dispatch, rejectWithValue }) => {
    try {

      dispatch(prompt({
        type: MessageTypes.CLI,
        message: `You are settling into your ship, making sure everything is in place and secure.\nWith a sense of excitement and anticipation, you are ready to embark on a grand adventure, filled with unknown destinations and endless possibilities. ... \n`
      }))

      const response = await axios.post('http://localhost:5001/space/game/start', { ship: arg.ship });


      dispatch(setThread(response.data.thread))

      return response.data;
    } catch (error:any) {
      return rejectWithValue(error);
    }
  }
);

export const createShip = createAsyncThunk(
  'cli/createShip',
  async (arg, { dispatch, rejectWithValue }) => {
    try {

      dispatch(prompt({
        type: MessageTypes.CLI,
        message: `You are entering the hangar, and you feel a mix of excitement and anticipation.\nYou are ready to receive your ship, which you have been eagerly awaiting for some time.\nAs you step into the vast space, you can’t help but wonder what luck is in store for you and what adventures await. The hangar is bustling with activity, and the atmosphere is charged with a sense of possibility.\nYou take a deep breath, ready to embrace whatever the future holds. ... \n`
      }))

      dispatch(setIsLoading(true))

      const response = await axios.post('http://localhost:5001/space/game/ship');

      dispatch(setShip(response.data))

      dispatch(setIsLoading(false))

      dispatch(prompt({
        type: MessageTypes.CLI,
        message: `Your ship has arrived! \n`
      }))

      return response.data;
    } catch (error:any) {
      return rejectWithValue(error);
    }
  }
);

type sendMessageArgs = {
  thread: string;
  message: string;
}

export const sendMessage = createAsyncThunk('cli/createShip',
  async (arg: sendMessageArgs, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true))
      const response = await axios.post(`http://localhost:5001/space/game/${arg.thread}/events`, { message: arg.message });
      dispatch(incrementEvent())

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
)

// Action creators are generated for each case reducer function
export const { prompt, clear, setThread, append , setShip, incrementEvent, setIsLoading, setOptions} = cliSlice.actions

export default cliSlice.reducer
