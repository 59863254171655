import React, { useEffect, useState } from 'react';
import {useSound} from './Soundboard';

const TypingEffect = ({ text, speed, resetable=false }: any) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const {play} = useSound();

  useEffect(() => {
    setDisplayedText('');
    setIndex(0);
  }, [])

  useEffect(() => {
    if(resetable){
      setDisplayedText('');
      setIndex(0);
    }
  }, [text])

  useEffect(() => {
    if (index < text.length) {
      play('keyboard');
      const timeout = setTimeout(async () => {
        setDisplayedText(displayedText + text.charAt(index));
        setIndex(index + 1);
      }, speed);
      return () => clearTimeout(timeout);
    }
  }, [index, text, displayedText, speed]);

  return <span className="py-4">{displayedText}</span>;
};

export default TypingEffect;
