import React from 'react';
import {StoreContext} from '../components/core/StoreDialog';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {fetchProducts} from '../store/payments';
import {useAuth0} from '@auth0/auth0-react';

export const useStore = () => {

  const dispatch = useAppDispatch();
  const context = React.useContext(StoreContext);
  const store = useAppSelector((state) => state.payments);
  const {getAccessTokenSilently, isAuthenticated} = useAuth0()
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }

  function doFetchProduct() {
    if (isAuthenticated) {
      getAccessTokenSilently().then(token => dispatch(fetchProducts(token)));
    }
  }

  return {...context, doFetchProduct, store};
}
