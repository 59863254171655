import {useAppDispatch, useAppSelector} from '../store/hooks';
import {useAuth0} from '@auth0/auth0-react';
import {useShips} from './useShips';
import {createGame as doCreateGame, stepGame as doStepGame} from '../store/game';

export const useGame = () => {
  const game = useAppSelector((state) => state.game);
  const {getAccessTokenSilently, isAuthenticated} = useAuth0()
  const {ships} = useShips()
  const dispatch = useAppDispatch()

  async function createGame() {
    if(isAuthenticated && ships.current){
      const token = await getAccessTokenSilently()
      return dispatch(doCreateGame({ship: ships.current.id, token}));
    }else{
      return dispatch(doCreateGame({}))
    }
  }

  async function stepGame(message: string) {
    if(isAuthenticated && ships.current){
      const token = await getAccessTokenSilently()
      return dispatch(doStepGame({message, thread: game.thread, token}))
    }else{
      return dispatch(doStepGame({message, thread: game.thread}))
    }

  }

  return {game, createGame, stepGame}
}
