'use client'

import React, { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {Button} from '../common/Button';
import {Menu} from './Menu';

export function Drawer({open, setOpen}: any) {


  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll shadow-xl bg-gray-800">
                <div className="relative flex-1">
                  <div className="absolute !z-30 top-1 left-1 ">
                  <button className="bg-gray-800 h-6 w-6 border-primary border transition-all ease-in-out hover:bg-gray-900 rounded-full"
                          onClick={() => setOpen(false)}>
                    <FontAwesomeIcon icon={faXmark} className='fa-xs text-primary'/>
                  </button>
                  </div>
                  <Menu/>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
