import React, {useState} from 'react';
import {ShipIcon} from './ShipIcon';
import {ShipPartsIcon} from './ShipPartsIcon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {MenuButton} from '../common/MenuButton';
import {Drawer} from './Drawer';

function Hamburger({setOpen}: any) {
  return (
    <div className='h-full w-14 flex items-center p-1 lg:hidden'>
      <MenuButton onClick={() => setOpen(true)} icon={<FontAwesomeIcon icon={faBars} className="fa-lg" />} />
    </div>
  )
}

export function TopNav() {

  const [open, setOpen] = useState(false)

  return (
    <div className='bg-gray-900 h-[60px] sticky w-full z-10 top-0'>
      <div className="flex flex-row items-center h-full gap-1">
        <Hamburger setOpen={setOpen} />
        <Drawer open={open} setOpen={setOpen} />
        <ShipIcon />
        <ShipPartsIcon />
      </div>
    </div>
  )
}
