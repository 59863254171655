import {DialogContainer} from '../common/DialogContainer';
import {useEffect, useState} from 'react';
import {Ship} from '../common/ShipV2';
import {useShips} from '../../hooks/useShips';
import {Confetti} from '../common/Confetti';
import {Button} from '../common/Button';
import {Spinner} from '../common/Spinner';
import {setNewShip} from '../../store/ships';
import {useAppDispatch} from '../../store/hooks';
import {useSound} from '../common/Soundboard';


export function ShipCreatorDisplay() {

  const {ships, create, setShip} = useShips()
  const dispatch = useAppDispatch()
  const {play} = useSound()

  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (ships.new) {
      setOpen(true)
      play("notification")
    }
  }, [ships.new])

  return (
    <DialogContainer isOpen={isOpen} setOpen={setOpen} size="max-w-2xl">
      <div className="flex flex-col gap-2 ">
        <Confetti/>
        <h2 className="text-primary text-4xl font-vt text-center">A new ship has been created!</h2>
        {ships.new && <Ship ship={ships.new}/>}
        <div className="grid xs:grid-cols-1 md:grid-cols-4 gap-2 mt-4">

          <div className="md:col-span-2">
            <Button style="secondary" className="w-full md:w-fit" action={() => {
              setShip(ships.new)
              dispatch(setNewShip())
              setOpen(false)
            }}>
              <span className="line-clamp-1">Play with <span
                className="text-glare-secondary text-secondary">"{ships.current?.name}"</span></span>
            </Button>
          </div>

          <div className="md:col-span-2 place-self-end">
            <Button style="secondary" className="w-full md:w-fit font-bold flex flex-row gap-2 items-center "
                    action={create}>
              <span className={`${ships.isLoading ? '' : 'animate-pulse'}`}>Craft Another</span> <Spinner
              isLoading={ships.isLoading}/>
            </Button>
          </div>
        </div>
      </div>
    </DialogContainer>
  );
}
