import { configureStore } from '@reduxjs/toolkit'
import cli from './cli';
import ships from './ships'
import game from './game'
import payments from './payments';
import auth from './auth';

export const store = configureStore({
  reducer: {
    cli,
    ships,
    game,
    payments,
    auth,
  },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
