import {Popover} from '@headlessui/react';
import {Ship} from '../common/ShipV2';
import React, {useEffect, useRef} from 'react';
import {useShips} from '../../hooks/useShips';
import {getPinataUrl} from '../../Utils';
import TypingEffect from '../common/TypingEffect';
import {useGame} from '../../hooks/useGame';
import {useAuth0} from '@auth0/auth0-react';

const IDLE_MESSAGES = [
  'Hovering cosmic nebula!',
  'Exploring the unknown!',
  'Sailing the stars!',
  'Navigating the void!',
  'Seeking the unknown!',
  'Sailing the cosmos!',
  'Expecting comands!',
  'Awaiting instructions!',
  'Awaiting your orders!',
  'Awaiting your command!',
  'Awaiting your instructions!',
  'Awaiting your orders!',
  'Clearing thursters!',
  'Orbiting the sun!',
  'Orbiting the moon!',
  'Orbiting the planet!',
  'Orbiting the star!',
  'Orbiting the galaxy!',
  'Orbiting the universe!',
  'Orbiting the cosmos!',
  'Orbiting the void!',
]

function getMessage() {
  return IDLE_MESSAGES[Math.floor(Math.random() * IDLE_MESSAGES.length)]
}

export function ShipIcon() {
  const {ships:{current, list}} = useShips()
  const {game}= useGame()
  const [message, setMessage] = React.useState<string>(getMessage())

  const {isAuthenticated} = useAuth0();

  useEffect(() => {
      if(!isAuthenticated){
        setMessage("There must be a better ship somerwhere")
      }else{
        setMessage(getMessage())
      }

  }, [isAuthenticated]);


  // useEffect(() => {
  //   if (current && popoverButtonRef.current) {
  //     popoverButtonRef.current.click();
  //   }
  // }, [list]);

  const show = current ? 'opacity-100' : 'opacity-0'

  useEffect(() => {
    if(game.current?.finished){
      if(!isAuthenticated){
        setMessage("There must be a better ship somerwhere")
      }else{
        setMessage(getMessage())
      }
    }else if(game.current){
      setMessage("In mission: " + game.current?.name)
    }
  }, [game.current]);

  if (current) {
    return (
      <Popover className={`relative ring-0 transition-opacity duration-700 ease-in-out ${show} `}>
        {({open}) => (
          <>
            <Popover.Button
              as="div"
              className={`${open?'bg-gray-800':''} cursor-pointer ring-0 p-2 h-[50px] flex flex-row gap-3 items-center focus:ring-0 rounded max-w-[270px] lg:max-w-[370px]`}>
              <div className="border border-primary rounded flex h-auto max-w-10">
                <img src={getPinataUrl(current.image)} alt={current?.name} className="h-auto w-full rounded"/>
              </div>
              <div className="flex flex-col itens-center">
                <div className="text-glare font-vt text-sm break-words line-clamp-1 max-w-15"><TypingEffect text={current.name} speed={10} resetable={true}/></div>
                <div className="text-glare-secondary font-vt text-xs break-words line-clamp-1 max-w-15"><TypingEffect text={message} speed={10} resetable={true} /></div>
              </div>
            </Popover.Button>

            <Popover.Panel
              transition
              anchor="bottom"
              className="absolute my-2 py-2 !max-w-md !z-20">
              <Ship ship={current} />
            </Popover.Panel>
          </>
        )}
      </Popover>
    );
  } else {
    return <></>;
  }
}
