import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL

type PaymentsState = {
  products: any[]
}

const initialState: PaymentsState = {
  products: [],
}

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
  },
});

export const fetchProducts = createAsyncThunk('payments/products',
  async (token: string, {dispatch, rejectWithValue}) => {
    try {
      const response = await axios.get(`${url}/payments/products`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response
      dispatch(setProducts(result.data))
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const {setProducts} = paymentsSlice.actions

export default paymentsSlice.reducer;
