import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {RootState} from '../index';
import {MessageTypes, prompt, setIsLoading as setCliLoading} from '../cli';
import {me} from '../auth';

const url = process.env.REACT_APP_API_URL

export type Ship = {
  id?: string,
  name: string,
  hp: number,
  attack: number,
  defense: number,
  speed: number,
  image: string
  description: string,
  tier: string
  owner?: string
  level: number
  xp: number
  race: string
  traits: string[]
}

export type ShipsState = {
  current?: Ship,
  new?: Ship,
  list?: Array<Ship>,
  isLoading: boolean
}

const initialState: ShipsState = {
  current: undefined,
  list: undefined,
  isLoading: false
}

export const shipsSlice = createSlice({
    name: 'ships',
    initialState,
    reducers: {
      setCurrentShip(state: any, action: PayloadAction<Ship>) {
        state.current = action.payload
      },
      setShips(state: any, action: PayloadAction<Ship[]>) {
        state.list = action.payload
      },
      setIsLoading(state : any, action : PayloadAction<boolean>){
        state.isLoading = action.payload
      },
      setNewShip(state: any, action: PayloadAction<Ship|undefined>) {
        state.new = action.payload
      }
    },
  },
)
export const createShip = createAsyncThunk('ships/createShip',
  async (arg: any, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(setCliLoading(true))
      dispatch(setIsLoading(true))
      dispatch(prompt({
        type: MessageTypes.CLI,
        message: `You are entering the hangar, and you feel a mix of excitement and anticipation. You are ready to receive your ship, which you have been eagerly awaiting for some time.\nAs you step into the vast space, you can’t help but wonder what luck is in store for you and what adventures await. The hangar is bustling with activity, and the atmosphere is charged with a sense of possibility. You take a deep breath, ready to embrace whatever the future holds. ... \n`
      }))
      const result = await axios.post(`${url}/ships`, undefined,{
        headers: {
          Authorization: `Bearer ${arg.token}`
        }
      });

      const state = getState() as RootState;
      const ships = state.ships.list;

      dispatch(setCurrentShip(result.data))
      const currentShips = ships?ships:[]
      dispatch(setNewShip(result.data))
      dispatch(setShips([result.data, ...currentShips]))
      dispatch(setCliLoading(false))
      dispatch(setIsLoading(false))
      dispatch(me(arg.token))

      dispatch(prompt({
        type: MessageTypes.CLI,
        message: `Your ship has finally arrived!\nWe are thrilled to announce the arrival of the magnificent and powerful "${result.data.name}" Please join us in giving a warm and enthusiastic welcome to this incredible vessel that marks a new era in our journey.\nGet ready to embark on exciting adventures and explore uncharted territories with the "${result.data.name}" leading the way!`
      }))

    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
)

export const getShips = createAsyncThunk('ships/getShips',
  async (arg: any, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(setIsLoading(true))

      const result = await axios.get(`${url}/ships`, {
        headers: {
          Authorization: `Bearer ${arg.token}`
        }
      });

      if(result.data && result.data.length > 0){
        dispatch(setCurrentShip(result.data[0]))
      }
      dispatch(setShips(result.data))
      dispatch(setIsLoading(false))

    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
)

export const { setShips, setCurrentShip, setIsLoading, setNewShip} = shipsSlice.actions

export default shipsSlice.reducer
